




























































import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '@/includes/helpViews/editorButtonsHelpView.vue'
import Placeholders from '@/mixins/placeholders/placeholders'
import RulesCommandHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/RulesCommandHandlerModule/RulesCommandHandlerModuleBuilder'
import ModuleConfigSettings from '../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleEnableSwitch from '@/components/Modules/components/ModuleEnableSwitch.vue'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'

import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    MultiMessageEditorWithMediaInput,
    ModuleEnableSwitch,
  },
  data() {
    return {
      MediaEditorMode,
      EditorButtonsHelpView
    }
  },
})
export default class RulesCommandHandlerModuleView extends Mixins(InputSetups, Placeholders, ModuleBindings) {
  @VModel() module!: RulesCommandHandlerModuleBuilder
}
